import { firestoreDefaultConverter, globalFirestoreOptions } from "vuefire";
import {
  type QueryDocumentSnapshot,
  collection,
  doc,
  getDocs,
  getDoc,
  type WithFieldValue,
  type DocumentData,
} from "firebase/firestore";
import { ref } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import { auth, fsdb, storage, functions, remoteConfig } from "./config";

import type {
  VeoReservation,
  Team,
  User,
  Game,
  Practice,
  AppSetting,
  DocumentMetaData,
  Permit,
  Player,
  Season,
  ClubSeason,
  PracticeSchedule,
  File,
  Venue,
  PracticeTimeSlot,
  Notice,
} from "@/types/sesc";

// This ensures that we keep id and other metadata as a part of the snapshot when
// using vuefire and useCollection with these db utility calls
globalFirestoreOptions.converter = {
  toFirestore: firestoreDefaultConverter.toFirestore,
  fromFirestore: (snapshot, options) => {
    const data = firestoreDefaultConverter.fromFirestore(snapshot, options);
    if (!data) return null;
    // data.metadata = snapshot.metadata;
    return data;
  },
};

const converter = <T>() => ({
  toFirestore: (data: WithFieldValue<T>) => {
    return globalFirestoreOptions.converter.toFirestore(data);
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const baseData = globalFirestoreOptions.converter.fromFirestore(
      snapshot,
      {},
    );
    return baseData as T;
  },
});
const dataPoint = <T extends DocumentData>(collectionPath: string) =>
  collection(fsdb, collectionPath).withConverter(converter<T>());
const docPoint = <T extends DocumentData>(collectionPath: string, id: string) =>
  doc(fsdb, collectionPath, id).withConverter(converter<T>());

const db = {
  reservations: dataPoint<VeoReservation>(`/veo-reservations`),
  reservation: (id: string) =>
    docPoint<VeoReservation>(`/veo-reservations`, id),
  teams: dataPoint<Team>(`/teams`),
  team: (id: string) => docPoint<Team>(`/teams`, id),
  files: dataPoint<File>(`/files`),
  file: (id: string) => docPoint<File>(`/files`, id),
  games: (teamid: string) => dataPoint<Game>(`/teams/${teamid}/games`),
  game: (teamid: string, id: string) =>
    docPoint<Game>(`/teams/${teamid}/games`, id),
  notices: dataPoint<Notice>(`/notices`),
  notice: (id: string) => docPoint<Notice>(`/notices`, id),
  permits: dataPoint<Permit>(`/permits`),
  permit: (id: string) => docPoint<Permit>(`/permits`, id),
  players: dataPoint<Player>(`/players`),
  player: (id: string) => docPoint<Player>(`/players`, id),
  practiceSchedules: dataPoint<PracticeSchedule>(`/practice-schedules`),
  practiceSchedule: (id: string) =>
    docPoint<PracticeSchedule>(`/practice-schedules`, id),
  practiceTimeSlots: (practiceScheduleId: string) =>
    dataPoint<PracticeTimeSlot>(
      `/practice-schedules/${practiceScheduleId}/practice-time-slots`,
    ),
  practiceTimeSlot: (practiceScheduleId: string, practiceTimeSlotId: string) =>
    docPoint<PracticeTimeSlot>(
      `/practice-schedules/${practiceScheduleId}/practice-time-slots`,
      practiceTimeSlotId,
    ),
  practices: (teamid: string) =>
    dataPoint<Practice>(`/teams/${teamid}/practices`),
  practice: (teamid: string, id: string) =>
    docPoint<Practice>(`/teams/${teamid}/practices`, id),
  seasons: (teamid: string) => dataPoint<Season>(`/teams/${teamid}/seasons`),
  season: (teamid: string, id: string) =>
    docPoint<Season>(`/teams/${teamid}/seasons`, id),
  clubSeasons: dataPoint<ClubSeason>(`/seasons`),
  clubSeason: (id: string) => docPoint<ClubSeason>(`/seasons`, id),
  users: dataPoint<User>(`/users`),
  user: (id: string) => docPoint<User>(`/users`, id),
  venues: dataPoint<Venue>(`/venues`),
  venue: (id: string) => docPoint<Venue>(`/venues`, id),
  app: (id: string) => docPoint<AppSetting>(`/app`, id),
};

const fileRef = ref(storage, "files");

const getUser = async (uid: string) => {
  const usersnapshot = await getDoc(db.user(uid));
  if (usersnapshot.exists()) {
    return usersnapshot.data();
  }
  return null;
};

export const getTeams = async (): Promise<Team[]> => {
  const teamsSnap = await getDocs(db.teams);
  console.log(teamsSnap.docs);
  return Promise.all(
    teamsSnap?.docs?.map(async (team) => {
      const teamData: Team = { id: team.id, ...team.data() };
      if (teamData?.currentSeason) {
        await getDoc(db.season(team.id, teamData.currentSeason as string)).then(
          (seasonSnap) => {
            if (seasonSnap.exists()) {
              teamData.currentSeason = {
                id: seasonSnap.id,
                ...seasonSnap.data(),
              };
            }
          },
        );
      }
      return teamData;
    }),
  );
};

const prepareData = <T>(data: T) => {
  const currentUser = auth.currentUser;
  if (!currentUser) return { ...data };
  return {
    lastUpdated: new Date().toISOString(),
    lastUpdatedBy: currentUser.uid,
    ...data,
  } as T & DocumentMetaData;
};

const createFunction = <T = any, R = any>(
  name: string,
): ((data: T) => Promise<R>) => {
  const callable = httpsCallable(functions, name);
  return async (data: T) => (await callable(data)).data as Promise<R>;
};

export {
  db,
  fileRef,
  storage,
  createFunction,
  prepareData,
  getUser,
  remoteConfig,
};
export default db;
