import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from "vue-router";
import { getDoc } from "firebase/firestore";
import { getCurrentUser } from "vuefire";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import db from "@/firebase/util";

async function adminGuard() {
  const user = await getCurrentUser();
  if (user) {
    const idTokenResult = await user.getIdTokenResult();
    const isAdminUser = idTokenResult?.claims?.admin;
    if (!isAdminUser) {
      alert("Access Denied. You do not have permission to view that.");
      return {
        path: "/",
      };
    }
  }
}

async function directorGuard() {
  const user = await getCurrentUser();
  if (user) {
    const idTokenResult = await user.getIdTokenResult();
    const isAdminUser = idTokenResult?.claims?.admin;
    const isDirectorUser = idTokenResult?.claims?.director;
    if (!isDirectorUser && !isAdminUser) {
      alert("Access Denied. You do not have permission to view that.");
      return {
        path: "/",
      };
    }
  }
}

async function teamGuard(to: RouteLocationNormalized) {
  const user = await getCurrentUser();
  if (user) {
    const teamSnap = await getDoc(db.team(to.params.id as string));
    if (teamSnap.exists()) {
      const coaches = teamSnap.data().coachesUidList;
      if (!coaches?.includes(user.uid)) {
        const idTokenResult = await user.getIdTokenResult();
        const isAdminUser = idTokenResult?.claims?.admin;
        if (!isAdminUser) {
          alert("Access Denied. You are not a coach of this team.");
          return `/teams/${to.params.id}`;
        }
      }
    } else {
      alert("That team does not exist.");
      return "/teams";
    }
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: DashboardView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/user/ProfileView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile/:id",
      name: "user-profile",
      component: () => import("@/views/user/ProfileView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile/edit",
      name: "profile-edit",
      component: () => import("@/views/user/ProfileEditView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/teams",
      name: "teams",
      component: () => import("@/views/teams/TeamsView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/teams/:id",
      name: "team",
      component: () => import("@/views/teams/TeamView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/teams/:id/edit",
      name: "team-edit",
      component: () => import("@/views/teams/TeamEditView.vue"),
      meta: {
        requiresAuth: true,
        teamGuard: true,
      },
    },
    {
      path: "/teams/:id/games",
      name: "team-games",
      component: () => import("@/views/teams/games/TeamGames.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/teams/:teamid/games/:id",
      name: "team-game",
      component: () => import("@/views/teams/games/TeamGame.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/teams/:id/practices",
      name: "team-practices",
      component: () => import("@/views/teams/practices/TeamPractices.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/teams/:teamid/practices/:id",
      name: "team-practice",
      component: () => import("@/views/teams/practices/TeamPractice.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/practice-schedules",
      name: "practice-schedules",
      component: () =>
        import("@/views/practiceSchedules/PracticeSchedulesView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/coaches",
      name: "coaches",
      component: () => import("@/views/coaches/CoachesView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/coaches/add",
      name: "coach-add",
      component: () => import("@/views/coaches/CoachAddView.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/coaches/:id",
      name: "coach",
      component: () => import("@/views/coaches/CoachView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/coaches/:id/edit",
      name: "coach-edit",
      component: () => import("@/views/coaches/CoachEditView.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/veo",
      name: "veo",
      component: () => import("@/views/veocam/VeoView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/veo/reserve",
      name: "veo-reserve",
      component: () => import("@/views/veocam/VeoView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/veo/reserve/:step",
      name: "veo-reserve-step",
      component: () => import("@/views/veocam/VeoView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/veo/reservations",
      name: "veo-reservations",
      component: () => import("@/views/veocam/VeoReservations.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/veo/reservations/:id",
      name: "edit-veo-reservation",
      component: () => import("@/views/veocam/VeoReservation.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/veo/tutorial",
      name: "veo-tutorial",
      component: () => import("@/views/veocam/VeoTutorialView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/veo/tutorials/:id",
      name: "veo-tutorial-article",
      component: () => import("@/views/veocam/VeoTutorialArticleView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/users",
      name: "users",
      component: () => import("@/views/admin/UsersView.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/users/add",
      name: "users-add",
      component: () => import("@/views/admin/UserAdd.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/users/:id",
      name: "users-edit",
      component: () => import("@/views/admin/UserEdit.vue"),
      children: [
        {
          path: "edit/:tab?",
          name: "users-edit-tab",
          component: () => import("@/views/admin/UserEdit.vue"),
          meta: {
            requiresAuth: true,
            adminGuard: true,
          },
        },
      ],
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/teams",
      name: "admin-teams",
      component: () => import("@/views/admin/teams/TeamsView.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/teams/:id/edit",
      name: "admin-team-edit",
      component: () => import("@/views/admin/teams/EditTeam.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/teams/:teamid/seasons/:id",
      name: "admin-team-season-edit",
      component: () => import("@/views/admin/teams/seasons/EditSeason.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/players",
      name: "admin-players",
      component: () => import("@/views/admin/players/PlayersView.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/players/add",
      name: "admin-player-add",
      component: () => import("@/views/admin/players/AddPlayer.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/players/:id/edit",
      name: "admin-player-edit",
      component: () => import("@/views/admin/players/EditPlayer.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/veo-reservations",
      name: "admin-veo-reservations",
      component: () => import("@/views/admin/VeoReservations.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/permits",
      name: "admin-permits",
      component: () => import("@/views/admin/PermitsList.vue"),
      children: [],
      meta: {
        requiresAuth: true,
        directorGuard: true,
      },
    },
    {
      path: "/admin/files",
      name: "admin-files",
      component: () => import("@/views/admin/FilesList.vue"),
      children: [],
      meta: {
        requiresAuth: true,
        directorGuard: true,
      },
    },
    {
      path: "/admin/seasons",
      name: "admin-seasons",
      component: () => import("@/views/admin/ClubSeasons.vue"),
      children: [],
      meta: {
        requiresAuth: true,
        directorGuard: true,
      },
    },
    {
      path: "/admin/notices",
      name: "admin-notices",
      component: () => import("@/views/admin/notices/NoticesView.vue"),
      children: [],
      meta: {
        requiresAuth: true,
        directorGuard: true,
      },
    },
    {
      path: "/admin/practice-schedules",
      name: "admin-practice-schedules",
      component: () =>
        import("@/views/admin/practiceSchedules/PracticeSchedules.vue"),
      children: [],
      meta: {
        requiresAuth: true,
        directorGuard: true,
      },
    },
    {
      path: "/admin/practice-schedules/:id/edit",
      name: "admin-practice-schedules-edit",
      component: () =>
        import("@/views/admin/practiceSchedules/PracticeScheduleEditForm.vue"),
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/admin/settings",
      name: "admin-settings",
      component: () => import("@/views/admin/AdminSettings.vue"),
      children: [
        {
          path: ":tab?",
          name: "admin-settings-tab",
          component: () => import("@/views/admin/AdminSettings.vue"),
          meta: {
            requiresAuth: true,
            adminGuard: true,
          },
        },
      ],
      meta: {
        requiresAuth: true,
        adminGuard: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/user/LoginView.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("@/views/user/LogoutView.vue"),
    },
    {
      path: "/forgotpassword",
      name: "forgot-password",
      component: () => import("@/views/user/ForgotPasswordView.vue"),
    },
  ],
});

router.beforeEach(async (to: RouteLocationNormalized) => {
  if (to.meta.requiresAuth) {
    const user = await getCurrentUser();
    if (!user) {
      return {
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      };
    }
  }

  if (to.meta.adminGuard) {
    return adminGuard();
  }

  if (to.meta.teamGuard) {
    return teamGuard(to);
  }
});

export default router;
