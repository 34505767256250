<script setup lang="ts">
import db from "@/firebase/util";
import { limit, orderBy, query } from "firebase/firestore";
import { useCollection } from "vuefire";
import DashboardNoticeItem from "./DashboardNoticeItem.vue";

const q = query(db.notices, orderBy("postedOn", "desc"), limit(3));
const notices = useCollection(q, { ssrKey: "admin-notices-list" });
</script>
<template>
  <div class="flex flex-col gap-4 mb-8">
    <div class="mb-2">
      <h3 class="text-white font-bold text-lg">SESC Coaching Updates</h3>
    </div>
    <div v-for="notice in notices" :key="notice.id">
      <DashboardNoticeItem :notice="notice" />
    </div>
  </div>
</template>
